<template>
  <div class="agriculturalDeatil">
    <div class="tabar">
      <span>您所在的位置：&nbsp;&nbsp;</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/portal/sciencePopularization' }"
          >在线科普</el-breadcrumb-item
        >
        <el-breadcrumb-item>科普详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="xwzxContent">
      <div class="leftContent">
        <div class="lf-title">{{ dateObj.title }}</div>
        <div class="messageContent">
          <div class="lf-message">
            <span style="color: #17a763">{{ dateObj.author }}</span>
            <span>{{ dateObj.createTime }}</span>
            <span>阅读{{ `(${dateObj.hit})` }}</span>
          </div>
          <div v-if="dateObj.content" class="ms-text">
            <div class="ms-Cbox" v-html="optContent(dateObj.content)"></div>
          </div>
          <!-- <div v-if="picList" class="ms-Pic">
            <img
              v-for="(item, index) in imgList"
              :key="index"
              :src="item"
              alt=""
            />
          </div> -->
        </div>
      </div>
      <div class="rightContent">
        <div class="rct-Box">
          <div class="rc-title">热门资讯</div>
        </div>
        <div class="pic-list">
          <div v-for="(item, index) in picList" :key="index" class="picL-box">
            <img @click="openDetail(item)" :src="optImage(item)" alt="" />
            <div @click="openDetail(item)" class="picL-text">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getItemArticleContent } from "@/api/serviechall";
export default {
  data() {
    return {
      picList: [],
      dateObj: {},
      imgList: [],
    };
  },
  methods: {
    openDetail(item) {
      let query = { id: item.id };
      this.getItemArticleContent(query);
    },
    getItemArticleContent(query) {
      getItemArticleContent(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          const { cmsItemArticle, hotItemArticleList } = data;
          this.dateObj = cmsItemArticle;
          this.picList = hotItemArticleList || [];
          if (cmsItemArticle.coverImage) {
            this.imgList = cmsItemArticle.coverImage.split(",");
          }
        }
      });
    },
    optContent(content) {
      if (content && this.isBase64(content)) {
        return decodeURIComponent(escape(window.atob(content)));
      }
      return content;
    },
    isInclude(URL) {
      if (URL.indexOf("%") > -1) {
        return URL.replace(/%/g, "%25");
      } else {
        return URL;
      }
    },
    isBase64(str) {
      str = String(str);
      if (str === "" || str.trim() === "") {
        return false;
      }
      try {
        return btoa(atob(str)) == str;
      } catch (err) {
        return false;
      }
      return true;
    },
    optImage(item) {
      if (item.coverImage) {
        return item.coverImage.split(",")[0];
      }
    },
  },
  created() {
    let query = { id: this.$route.query.id };
    this.getItemArticleContent(query);
  },
};
</script>
<style lang="scss" scoped>
.agriculturalDeatil {
  width: 100%;
  .tabar {
    height: 52px;
    width: 1396px;
    margin: 0 auto 20px;
    color: #555;
    font-size: 12px;
    display: flex;
    align-items: center;
    /deep/ .el-breadcrumb {
      font-size: 12px;
      color: #555;
    }
  }
  .xwzxContent {
    width: 1396px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .leftContent {
      width: 885px;
      .lf-title {
        width: 100%;
        word-break: break-all;
        text-align: center;
        font-size: 26px;
        color: #333333;
      }
      .messageContent {
        width: 786px;
        margin: 0 auto;
        .lf-message {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 70px;
          font-size: 12px;
          color: #666;
          border-bottom: 1px solid #ddd;
        }
        .ms-text {
          width: 786px;
          margin-top: 28px;
          font-size: 14px;
          color: #666666;
          line-height: 25px;
          text-indent: 2em;
          word-break: break-all;
          padding-bottom: 20px;
          margin-bottom: 20px;
          overflow: hidden;
          /deep/ img {
            width: 786px !important;
          }
        }
        .ms-Pic {
          width: 100%;
          img {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
    }
    .rightContent {
      width: 420px;
      .rc-title {
        width: 107px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        margin-right: 23px;
        font-size: 20px;
        color: #333;
        cursor: pointer;
        border-bottom: 3.5px solid #17a763;
      }
      .rct-Box {
        width: 100%;
        height: 49px;
        border-bottom: 1px solid #ddd;
      }
      .pic-list {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        .picL-box {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          width: 100%;
          img {
            width: 114px;
            height: 82px;
          }
          .picL-text {
            cursor: pointer;
            width: 245px;
            font-size: 14px;
            color: #333;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 28px;
            height: 50px;
          }
          .picL-text:hover {
            color: #17a763;
          }
        }
      }
    }
  }
}
</style>